import { lazy } from "react";

// project imports
import Loadable from "@components/shared/Loadable";
import MainLayout from "@/layout/MainLayout";
import ProtectedRoutes from "./MainRoutes/ProtectedRoutes";

// routes
const Home = Loadable(lazy(() => import("@pages/Home")));
const AllCurrencies = Loadable(
  lazy(() => import("@pages/Currency/AllCurrencies"))
);
const CreateCurrency = Loadable(
  lazy(() => import("@pages/Currency/CreateCurrency"))
);
const EditCurrency = Loadable(
  lazy(() => import("@pages/Currency/EditCurrency"))
);
const AllCountries = Loadable(
  lazy(() => import("@pages/Country/AllCountries"))
);
const CreateCountry = Loadable(
  lazy(() => import("@pages/Country/CreateCountry"))
);
const EditCountry = Loadable(lazy(() => import("@pages/Country/EditCountry")));
const AllCities = Loadable(lazy(() => import("@pages/City/AllCities")));
const CreateCity = Loadable(lazy(() => import("@pages/City/CreateCity")));
const EditCity = Loadable(lazy(() => import("@pages/City/EditCity")));
const AllCategories = Loadable(
  lazy(() => import("@pages/Category/AllCategories"))
);
const CreateCategory = Loadable(
  lazy(() => import("@pages/Category/CreateCategory"))
);
const EditCategory = Loadable(
  lazy(() => import("@pages/Category/EditCategory"))
);
const AllMembers = Loadable(lazy(() => import("@pages/Members/AllMembers")));
const ViewMember = Loadable(lazy(() => import("@pages/Members/ViewMember")));
const AllLogs = Loadable(lazy(() => import("@pages/Logs/AllLogs")));
const ViewLog = Loadable(lazy(() => import("@pages/Logs/ViewLog")));
const MainRoutes = {
  path: "/",
  element: (
    <ProtectedRoutes>
      <MainLayout />
    </ProtectedRoutes>
  ),
  children: [
    {
      index: true,
      element: <Home />,
    },
    {
      path: "currency",
      children: [
        {
          index: true,
          element: <AllCurrencies />,
        },
        {
          path: "new",
          element: <CreateCurrency />,
        },
        {
          path: ":currencyId",
          children: [
            {
              path: "edit",
              element: <EditCurrency />,
            },
          ],
        },
      ],
    },
    {
      path: "country",
      children: [
        { index: true, element: <AllCountries /> },
        {
          path: "new",
          element: <CreateCountry />,
        },
        {
          path: ":countryId",
          children: [
            {
              path: "edit",
              element: <EditCountry />,
            },
          ],
        },
      ],
    },
    {
      path: "city",
      children: [
        {
          index: true,
          element: <AllCities />,
        },
        {
          path: "new",
          element: <CreateCity />,
        },
        {
          path: ":cityId",
          children: [
            {
              path: "edit",
              element: <EditCity />,
            },
          ],
        },
      ],
    },
    {
      path: "category",
      children: [
        {
          index: true,
          element: <AllCategories />,
        },
        {
          path: "new",
          element: <CreateCategory />,
        },
        {
          path: ":categoryId",
          children: [
            {
              path: "edit",
              element: <EditCategory />,
            },
          ],
        },
      ],
    },
    {
      path: "members",
      children: [
        {
          index: true,
          element: <AllMembers />,
        },
        {
          path: ":memberId",
          children: [
            {
              path: "view",
              element: <ViewMember />,
            },
          ],
        },
      ],
    },
    {
      path: "logs",
      children: [
        {
          index: true,
          element: <AllLogs />,
        },
        {
          path: ":logId",
          children: [
            {
              path: "view",
              element: <ViewLog />,
            },
          ],
        },
      ],
    },
  ],
};

export default MainRoutes;
